import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import profileImage from "../images/profile_picture.jpg"

const Profile = () => {
    return (
        <Box elevation={0} component={Paper} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", p: 2 }}>
            <Box sx={{ height: 120, width: 120 }}>
                <img
                    alt='propic'
                    src={profileImage}
                    style={{
                        objectFit: 'cover',
                        height: '100%',
                        width: '100%',
                        borderRadius: '50%',
                        border: "1px solid #DAE0E2"
                    }}
                />
            </Box>
            <Box>
                <Typography variant='h6' fontWeight="boldF">Forhad Amin</Typography>
                <Box>
                    <Box display={"flex"}>
                        <Typography variant='body' fontWeight="bold"> Customer ID : </Typography>
                        <Typography variant='body' ml={2}> L3R144364 </Typography>
                    </Box>
                </Box>
                <Box display="flex" flexWrap="wrap">
                    <Typography variant='body' fontWeight="bold"> Service Name : </Typography>
                    <Typography variant='body' ml={2}> Woocommerce Website </Typography>
                </Box>
                <Box display="flex" flexWrap="wrap">
                    <Typography variant='body' fontWeight="bold"> Monthly </Typography>
                    <Typography variant='body' ml={2}>  1360 Taka</Typography>
                </Box>
                <Box display="flex" flexWrap="wrap">
                    <Typography variant='body' fontWeight="bold"> Balance :  </Typography>
                    <Typography variant='body' ml={2}>0.00 Taka</Typography>
                </Box>
            </Box>
        </Box>
    );
};
export default Profile;