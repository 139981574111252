import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';

const PhoneEmail = () => {
    return (
        <Paper sx={{ py: 5, display: "flex", justifyContent: "space-around", alignItems: "center", flexDirection: { md: "row", xs: "column", flexWrap: "wrap" } }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <LocalPhoneIcon sx={{ mr: 2, color: "#1287A5" }} />
                <Box>
                    <Typography m={1} sx={{ opacity: 0.8, fontWeight: "bold" }}>01781-881199</Typography>
                    <Typography m={1} sx={{ opacity: 0.8, fontWeight: "bold" }}> 01681-881199</Typography>
                </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
                <EmailIcon sx={{ mr: 2, color: '#1287A5' }} />
                <Box sx={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
                    <Typography m={1} sx={{ opacity: 0.8, fontWeight: "bold" }}> support@facreative.biz </Typography>
                    <Typography m={1} sx={{ opacity: 0.8, fontWeight: "bold" }}> sales@facreative.biz</Typography>
                    <Typography m={1} sx={{ opacity: 0.8, fontWeight: "bold" }}> hr@facreative.biz </Typography>
                </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
                <LanguageIcon sx={{ mr: 1, color: "#1287A5" }} />
                <Typography sx={{ opacity: 0.8, fontWeight: "bold", backgroundColor: "#1287A5", color: "white", px: 1 }}> www.facreative.biz</Typography>
            </Box>

        </Paper>
    );
};

export default PhoneEmail;




