import { Box, Button, Paper, Typography } from '@mui/material';
import React from 'react';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ReferalBonusModal from './ReferalBonusModal';
import RedeemIcon from '@mui/icons-material/Redeem';

const ReferalDashboard = () => {

    return (
        <Box>


            <Paper sx={{ width: "100%", minHeight: 100, backgroundColor: "#FFF3CD", py: 4, mb: 2, display: "flex", justifyContent: "space-around" }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <RedeemIcon sx={{ color: "orange", fontSize: { md: 100, xs: 70 } }} />
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: "bold", fontSize: { md: 28, xs: 20 }, opacity: 0.8, color: "#192A56" }}>Referal Bonus</Typography>
                    <Typography sx={{ fontWeight: "bold", fontSize: { md: 35, xs: 28 }, opacity: 0.8, color: "#758AA2" }}>260</Typography>
                    <ReferalBonusModal />
                </Box>
            </Paper>


            <Paper sx={{ py: 3, px: 2 }}>
                <Box sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, mt: 1 }}>

                    <Box>
                        {/* account status */}
                        <Box sx={{ backgroundColor: "#192A56", height: 100, width: 250, borderRadius: 2, py: 3, mr: 1, mb: 1, display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <Box>
                                <img src='' alt='' />
                                <AssignmentIndOutlinedIcon fontSize='large' sx={{ color: "white" }} />
                            </Box>
                            <Box>
                                <Typography color="white" fontSize={24} fontWeight="bold">Total Referal</Typography>
                                <Typography color="white" fontWeight="bold" sx={{ backgroundColor: "orange", py: 0.3, width: 70, borderRadius: 4 }}> 4 </Typography>
                            </Box>
                        </Box>

                        {/* DUE */}
                        <Box sx={{ backgroundColor: "#E1DA00", height: 100, width: 250, borderRadius: 2, py: 3, mr: 1, mb: 1, display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <Box>
                                <img src='' alt='' />
                                <AssignmentIndOutlinedIcon fontSize='large' sx={{ color: "black" }} />
                            </Box>
                            <Box>
                                <Typography color="black" fontSize={24} fontWeight="bold" > Total Success </Typography>
                                <Typography color="black" fontWeight={"bold"} sx={{ backgroundColor: "white", py: 0.3, width: 70, borderRadius: 4 }}>2</Typography>
                            </Box>
                        </Box>


                    </Box>

                    {/* Referal */}
                    <Box>

                        <Box sx={{ backgroundColor: "#10A881", height: 100, width: 250, borderRadius: 2, py: 3, mr: 1, mb: 1, display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <Box>
                                <img src='' alt='' />
                                <AssignmentIndOutlinedIcon fontSize='large' sx={{ color: "white" }} />
                            </Box>
                            <Box>
                                <Typography color="white" fontSize={24} fontWeight="bold" >Total Pending</Typography>
                                <Typography color="white" fontWeight={"bold"} sx={{ backgroundColor: "black", py: 0.3, width: 70, borderRadius: 4 }}>1</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ backgroundColor: "#E5B143", height: 100, width: 250, borderRadius: 2, py: 3, mr: 1, mb: 1, display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <Box>
                                <img src='' alt='' />
                                <AssignmentIndOutlinedIcon fontSize='large' sx={{ color: "black" }} />
                            </Box>
                            <Box>
                                <Typography color="black" fontSize={24} fontWeight="bold">Total Rejected</Typography>
                                <Typography color="black" fontWeight={"bold"} sx={{ backgroundColor: "white", py: 0.3, width: 70, borderRadius: 4 }} >1</Typography>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default ReferalDashboard;