import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import LogoutIcon from '@mui/icons-material/Logout';
import SwitchLeftOutlinedIcon from '@mui/icons-material/SwitchLeftOutlined';
import SwitchRightOutlinedIcon from '@mui/icons-material/SwitchRightOutlined';
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

const SideNav = () => {

    const { collapseSidebar } = useProSidebar();
    const navigate = useNavigate();

    return (
        <Box id="app" sx={{ minHeight: "50vh", }}>
            <Sidebar style={{ height: "87vh" }}
                backgroundColor="#2C3335"
            >
                <Menu
                    menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            if (level === 0)
                                return {
                                    color: disabled ? 'white' : '#DAE0E2',
                                    backgroundColor: active ? '#eecef9' : undefined,
                                    fontWeight: "bold"
                                };
                        },
                    }}
                >
                    <MenuItem
                        icon={<SwitchLeftOutlinedIcon />}
                        onClick={() => {
                            collapseSidebar();
                        }}
                        style={{ textAlign: "center" }}
                    >

                        Close Menu <SwitchRightOutlinedIcon />
                    </MenuItem>
                    <Box>
                        <MenuItem icon={<DashboardRoundedIcon />} onClick={() => navigate("/dashboard")}>Dashboard</MenuItem>
                        <MenuItem icon={<ManageAccountsIcon />} onClick={() => navigate("/myAccount")}>My Account</MenuItem>
                        <MenuItem icon={<CurrencyBitcoinIcon />} onClick={() => navigate("/billing")} >Billing</MenuItem>
                        <MenuItem icon={<HomeRepairServiceIcon />} onClick={() => navigate("/services")}>Services</MenuItem>
                        <MenuItem icon={<ContactPhoneIcon />} onClick={() => navigate("/supportTicket")} >Support Ticket</MenuItem>
                        <MenuItem icon={<Diversity2Icon />} onClick={() => navigate("/referal")} >Refer a Friend</MenuItem>
                        <MenuItem icon={<PermPhoneMsgIcon />} onClick={() => navigate("/contactUs")} >Contact Us</MenuItem>
                        <MenuItem icon={<LogoutIcon />} onClick={() => navigate("/")}>Logout</MenuItem>
                    </Box>
                </Menu>
            </Sidebar>
        </Box>
    );
}
export default SideNav;