import React from 'react';
import CardItem from './CardItem';
import { serviceList } from '../../demoDb/serviceList';
import { Box, Card, CardHeader, CardMedia, CardContent, Typography, } from '@mui/material';
import domainImage from "../../images/domian.png"
import hostingImage from "../../images/hosting.png"

const ServiceCards = () => {
    return (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {/* Domain */}
            <Card sx={{ maxWidth: 345, margin: 3, height: "max-content" }}>
                <CardHeader
                    title={<span style={{ fontWeight: "bold" }}>Domain Registration</span>}
                    sx={{ fontSize: 30 }}
                />
                <CardMedia component="img" height="194" image={domainImage} alt="Paella dish" />
                <CardContent>
                    <Box sx={{ border: "1px solid gray", p: 1, pl: 1, display: "flex" }}>
                        <Typography textAlign="left" > <span style={{ fontSize: "20px" }}> ◆ </span> .com </Typography>
                    </Box>
                    <Box sx={{ border: "1px solid gray", p: 1, pl: 1, display: "flex" }}>
                        <Typography textAlign="left" > <span style={{ fontSize: "20px" }}> ◆ </span> .net </Typography>
                    </Box>
                    <Box sx={{ border: "1px solid gray", p: 1, pl: 1, display: "flex" }}>
                        <Typography textAlign="left" > <span style={{ fontSize: "20px" }}> ◆ </span> .org </Typography>
                    </Box>
                    <Box sx={{ border: "1px solid gray", p: 1, pl: 1, display: "flex" }}>
                        <Typography textAlign="left" > <span style={{ fontSize: "20px" }}> ◆ </span> .tv </Typography>
                    </Box>
                </CardContent>
            </Card>

            {/* Hosting */}
            <Card sx={{ maxWidth: 345, margin: 3, height: "max-content" }}>
                <CardHeader
                    title={<span style={{ fontWeight: "bold" }}>Hosting</span>}
                    sx={{ fontSize: 30 }}
                />
                <CardMedia component="img" height="194" image={hostingImage} alt="Paella dish" />
                <CardContent>
                    <Box sx={{ border: "1px solid gray", p: 1, pl: 1, display: "flex" }}>
                        <Typography textAlign="left" > <span style={{ fontSize: "20px" }}> ◆ </span>  SINGAPORE (SG)  </Typography>
                    </Box>
                    <Box sx={{ border: "1px solid gray", p: 1, pl: 1, display: "flex" }}>
                        <Typography textAlign="left" > <span style={{ fontSize: "20px" }}> ◆ </span> UNITED STATES (US) </Typography>
                    </Box>
                    <Box sx={{ border: "1px solid gray", p: 1, pl: 1, display: "flex" }}>
                        <Typography textAlign="left" > <span style={{ fontSize: "20px" }}> ◆ </span> BANGLADESH (BDIX)</Typography>
                    </Box>
                    <Box sx={{ border: "1px solid gray", p: 1, pl: 1, display: "flex" }}>
                        <Typography textAlign="left" > <span style={{ fontSize: "20px" }}> ◆ </span> GERMANY (DE) </Typography>
                    </Box>
                </CardContent>
            </Card>
            {/* end hosting */}

            {
                serviceList.map((item, i) => (
                    <CardItem key={i} data={item} />
                ))
            }

        </Box>
    );
};

export default ServiceCards;