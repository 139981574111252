import React from 'react';
import { ticketHistory } from '../../demoDb/ticketHistoryData';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';

const TicketTable = () => {
    return (
        <Paper sx={{ width: { md: "80%", xs: "100%" }, p: 2 }}>
            <Typography variant='h6' fontWeight="bold"> Ticket History </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>id</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }} align="right">Date</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }} align="right">Problem</TableCell>
                            <TableCell sx={{ fontWeight: "bold", display: { xs: "none", md: "block" } }} align="right">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ticketHistory.map((item, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row"> {item.id}</TableCell>
                                <TableCell align="right">{item.date}</TableCell>
                                <TableCell align="right">
                                    <span style={{ backgroundColor: "#F3CC79", padding: "5px", borderRadius: "5px" }} >{item.problem}</span>
                                </TableCell>

                                <TableCell align="right" sx={{ display: { xs: "none", md: "block" } }}>
                                    <Button color={item.status === "Pending" ? "warning" : "success"}>
                                        {item.status}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};
export default TicketTable;