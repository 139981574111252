import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { Box, Divider, TextField, Typography } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, textAlign: "center" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        borderRadius: 3,
                        px: 3,
                        mb: 2
                    }}
                >
                    <DisabledByDefaultIcon fontSize='40px' color='error' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const ReferalBonusModal = () => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleUseReferal = () => {
        // Save Action will be implemented here
        setOpen(false) // After Saving Complete
    };

    return (
        <Box sx={{ marginBottom: 5, width: "100%" }} >
            <Button variant="outlined" onClick={handleClickOpen}>
                Use Referal Bonus
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>

                </BootstrapDialogTitle>
                <Divider sx={{ marginTop: 3 }} >Referal Bonus Cash</Divider>
                <DialogContent sx={{ minWidth: "500px", my: 8 }}>
                    <Box>

                        <Typography sx={{ fontWeight: 'bold', fontSize: 20, opacity: 0.8, mb: 1 }}>
                            Enter Amount :</Typography>
                        <TextField label="Amount" color='warning' sx={{ width: { md: 480, xs: 300 } }} />
                    </Box>

                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}>

                    <Button autoFocus onClick={handleUseReferal} variant='contained' color='warning'>
                        Send Request
                    </Button>

                </DialogActions>
            </BootstrapDialog>
        </Box>
    );
}
export default ReferalBonusModal;