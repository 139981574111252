import './App.css';
import Navbar from './components/Navbar';
import { Box } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SideNav from './components/SideNav';
import Dashboard from './pages/Dashboard';
import Billing from './pages/Billing/index';
import Services from './pages/Services/index';
import Packages from './pages/Packages/index';
import Ticket from './pages/Ticket/index';
import Referal from './pages/Referal/index';
import ContactUs from './pages/ContactUs/index';
import MyAccount from './pages/MyAccount/index';
import Auth from './components/Auth';

function App() {
  return (

    <Router>
      <Box minHeight="100vh" className="App" >
        <Navbar />
        <Box display={"flex"}>
          <Routes>

            <Route path='/' element={<Auth />} />
            <Route path='/dashboard' element={
              <Box sx={{ display: "flex", width: "100%" }}>
                <Box display={{ md: "block", xs: "none" }} sx={{ width: "13%" }}>
                  <SideNav />
                </Box>
                <Box sx={{ width: { md: "85%", xs: "100%" } }}>
                  <Dashboard />
                </Box>
              </Box>} />

            <Route path='/myAccount' element={<>
              <Box display={{ md: "block", xs: "none" }}>
                <SideNav />
              </Box>
              <MyAccount />
            </>} />

            <Route path='/billing' element={<>
              <Box display={{ md: "block", xs: "none" }}>
                <SideNav />
              </Box>
              <Billing />
            </>} />

            <Route path='/services' element={<>
              <Box display={{ md: "block", xs: "none" }}>
                <SideNav />
              </Box>
              <Services />
            </>} />

            <Route path='/packages' element={<>
              <Box display={{ md: "block", xs: "none" }}>
                <SideNav />
              </Box>
              <Packages />
            </>} />
            <Route path='/supportTicket' element={<>
              <Box display={{ md: "block", xs: "none" }}>
                <SideNav />
              </Box>
              <Ticket />
            </>} />
            <Route path='/referal' element={<>
              <Box display={{ md: "block", xs: "none" }}>
                <SideNav />
              </Box>
              <Referal />
            </>} />
            <Route path='/contactUs' element={<>
              <Box display={{ md: "block", xs: "none" }}>
                <SideNav />
              </Box>
              <ContactUs />
            </>} />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}
export default App;