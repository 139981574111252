export const ticketHistory = [
    {
        id: "1234",
        date: "12.02.2024",
        problem: "Site Speed Slow",
        status: "Pending"
    },
    {
        id: "2345",
        date: "11.02.2024",
        problem: "Single Page Style break",
        status: "Resolved"
    },
    {
        id: "3456",
        date: "10.02.2024",
        problem: "New Ad setup",
        status: "Resolved"
    }
];