import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem, Fade } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import LogoutIcon from '@mui/icons-material/Logout';

const MobileMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();

    return (
        <div>
            <Button
                id="fade-button"
                color='inherit'
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ display: { md: "none", xs: "block" } }}
            >
                <MenuIcon sx={{ color: "#014256", fontWeight: "bold", fontSize: "46px" }} />
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={() => { handleClose(); navigate("/dashboard") }}>
                    <DashboardRoundedIcon sx={{ mr: 3 }} />
                    Dashboard
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); navigate("/myAccount") }}>
                    <ManageAccountsIcon sx={{ mr: 3 }} />
                    My Account
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); navigate("/billing") }}>
                    <CurrencyBitcoinIcon sx={{ mr: 3 }} />
                    Billing
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); navigate("/services") }}>
                    <HomeRepairServiceIcon sx={{ mr: 3 }} />
                    Services
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); navigate("/supportTicket") }} >
                    <ContactPhoneIcon sx={{ mr: 3 }} />
                    Support Ticket
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); navigate("/referal") }} >
                    <Diversity2Icon sx={{ mr: 3 }} />
                    Refer a Friend
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); navigate("/contactUs") }} >
                    <PermPhoneMsgIcon sx={{ mr: 3 }} />
                    Contact Us
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); navigate("/") }}>
                    <LogoutIcon sx={{ mr: 3 }} />
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
}
export default MobileMenu;