import React from 'react';
import Paper from '@mui/material/Paper';
import { Box, Button, Divider, Typography } from '@mui/material';
import { ticketHistory } from './../demoDb/ticketHistoryData';

const TicketHistory = () => {

    return (

        <>
            <Paper sx={{ px: 2, py: 1 }}>
                <Box>
                    <Typography variant='h6' fontWeight="bold">Ticket / Support History</Typography>
                </Box>
                {
                    ticketHistory.map((item, index) => (
                        < Box key={index}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", py: 2 }}>
                                <Box sx={{ display: "flex", justifyContent: "start", flexDirection: "column", textAlign: "start" }}>
                                    <Typography fontWeight="bold">Id #{item.id}</Typography>
                                    <Typography>Date {item.date}</Typography>
                                    <Typography fontWeight="bold"> {item.problem}</Typography>
                                </Box>
                                <Box >
                                    <Button
                                        variant={item.status === "Pending" ? 'outlined' : "contained"}
                                        color={item.status === "Pending" ? "error" : "success"} >
                                        {item.status}
                                    </Button>
                                </Box>
                            </Box>
                            <Divider > </Divider>
                        </ Box>
                    ))
                }
            </Paper>
        </>
    );
}
export default TicketHistory;