import { Box, Typography } from '@mui/material';
import React from 'react';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';

const StatusBox = () => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: { md: "row", xs: "row" }, flexWrap: "wrap", mt: 1 }}>
            {/* account status */}
            <Box sx={{ backgroundColor: "#192A56", height: 100, width: { md: 295, xs: 160 }, borderRadius: 2, py: 3, mr: 1, mb: 1, display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                <Box>
                    <img src='' alt='' />
                    <AssignmentIndOutlinedIcon fontSize='large' sx={{ color: "white" }} />
                </Box>
                <Box>
                    <Typography color="white" fontSize={{ md: 24, xs: 15 }} fontWeight="bold">Account Status</Typography>
                    <Typography color="white" fontWeight="bold" sx={{ backgroundColor: "orange", py: 0.3, width: 70, borderRadius: 4 }}>active</Typography>
                </Box>
            </Box>
            {/* DUE */}
            <Box sx={{ backgroundColor: "#E1DA00", height: 100, width: { md: 295, xs: 160 }, borderRadius: 2, py: 3, mr: 1, display: "flex", justifyContent: "space-around", alignItems: "center", mb: 1, }}>
                <Box>
                    <img src='' alt='' />
                    <AssignmentIndOutlinedIcon fontSize='large' sx={{ color: "black" }} />
                </Box>
                <Box>
                    <Typography color="black" fontSize={{ md: 24, xs: 15 }} fontWeight="bold" >Due Balance</Typography>
                    <Typography color="black" fontSize={20} fontWeight={"bold"}>Tk. 550</Typography>
                </Box>
            </Box>
            {/* Referal */}
            <Box sx={{ backgroundColor: "#10A881", height: 100, width: { md: 295, xs: 160 }, borderRadius: 2, py: 3, mr: 1, display: "flex", justifyContent: "space-around", alignItems: "center", mb: 1, }}>
                <Box>
                    <img src='' alt='' />
                    <AssignmentIndOutlinedIcon fontSize='large' sx={{ color: "white" }} />
                </Box>
                <Box>
                    <Typography color="white" fontSize={{ md: 24, xs: 15 }} fontWeight="bold" >Total Referral</Typography>
                    <Typography color="white" fontSize={20} fontWeight={"bold"}>3</Typography>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: "#E5B143", height: 100, width: { md: 295, xs: 160 }, borderRadius: 2, py: 3, mr: 1, display: "flex", justifyContent: "space-around", alignItems: "center", mb: 1, }}>
                <Box>
                    <img src='' alt='' />
                    <AssignmentIndOutlinedIcon fontSize='large' sx={{ color: "black" }} />
                </Box>
                <Box>
                    <Typography color="black" fontSize={{ md: 24, xs: 15 }} fontWeight="bold">Notification</Typography>
                    <Typography color="black" fontSize={20} fontWeight={"bold"} >10</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default StatusBox;