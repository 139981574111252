import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import Profile from '../../components/Profile';
import PaymentChannel from '../../components/PaymentChannel';
import TicketHistory from '../../components/TicketHistory';
import PaymentHistory from '../../components/PaymentHistory';

const Billing = () => {
    return (
        <div>
            <Grid container ml={1} >
                <Grid item md={6} xs={12} sx={{ mt: 2, mr: 1, }} >
                    <PaymentChannel />
                </Grid>
                <Grid item md={4} xs={12} sx={{ mt: 2 }}>
                    <Profile />
                </Grid>
            </Grid>
            {/* Payment and Ticket History */}
            <Grid container ml={1} mt={2}>
                <Grid item md={9} xs={12} sx={{ mt: 2 }}>
                    <PaymentHistory />
                </Grid>
            </Grid>
        </div>
    );
};
export default Billing;