import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import { Box, Toolbar, Container } from '@mui/material';
import logo from "../images/logo/logo.png";
import MobileMenu from './MobileMenu';

const Navbar = () => {
    return (
        <AppBar position="static" sx={{ backgroundColor: "white", color: "black" }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <Box>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                                <img alt='' src={logo} style={{ height: 100 }} />
                            </Box>
                            <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                                <img alt='' src={logo} style={{ height: 90 }} />
                            </Box>
                        </Box>

                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <MobileMenu />
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Navbar;