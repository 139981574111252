import { FormControl, InputLabel, Paper, Select, Typography, MenuItem, Box, Button, TextField } from '@mui/material';
import React from 'react';

const ServiceRequest = () => {
    const [category, setCategory] = React.useState('');

    const handleChange = (event) => {
        setCategory(event.target.value);
    };

    return (
        <Box >

            <Paper sx={{ py: 7, backgroundColor: "#EAF0F1" }}>
                <Typography fontWeight="bold" variant='h6'>Get a New Service</Typography>
                <Box sx={{ mb: 2, px: { md: 20, xs: 2 }, maxWidth: 300 }}>
                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="demo-simple-select-standard-label"> Select Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={category}
                            onChange={handleChange}
                            label="Category"
                        >
                            <MenuItem value={"Website Problem"}> News Website </MenuItem>
                            <MenuItem value={"Mobile Apps Problem"}> Mobile Apps  </MenuItem>
                            <MenuItem value={"Software Problem"}> ERP Software </MenuItem>
                            <MenuItem value={"Domain & Hosting Renew"}> Domain & Hosting </MenuItem>
                            <MenuItem value={"Social Merketing"}> Social Merketing </MenuItem>
                            <MenuItem value={"Others"}> Others </MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ mb: 3, px: { md: 20, xs: 2 } }}>
                    <TextField
                        fullWidth
                        id="outlined-textarea"
                        label="Details:"
                        placeholder="write details about your service"
                        multiline
                        minRows={3}
                    />
                </Box>

                <Button variant='contained' color='secondary'>Submit Order</Button>
            </Paper>
        </Box>
    );
};
export default ServiceRequest;