import appImage from "../images/apps.png";
import marketingImage from "../images/marketing.png";
import softwareImage from "../images/Software.png";

export const serviceList = [
    {
        name: "Website Development",
        items: ["Single & Multi-Vendor E-Commerce.",
            "Online Newspaper / Portal",
            "Internet Protocol Television (IP TV)",
            "Organization/ NGO/Association",
            "School, Collage, University, Kindergarten",
            "Madrasah Website 3 language (Bangla + English + Arabic)",
            "Real Estate Company",
            "Hospital / Clinic/ Laboratories",
            "Law Firm, Consultancy firm",
            "Distribution, Marketing, Company",
            "Insurance and Micro Finance/ Multipurpose",
            "Personal/ Portfolio Website",
            "Buying House / Garments website",
            "Online Library Management (Sales & Rent)",
            "Beauty parlour and salon"
        ],
        image: softwareImage
    },
    {
        name: "Apps Development",
        items: ["News Portal App",
            "Daily Newspaper App",
            "Magazine App",
            "Online TV & IP TV",
            "E - commerce(Single & Multivendor)",
            "Education and E - Learning Apps",
            "Courier Service Management",
            "Distribution, Marketing Company",
            "Small & Super Shop Management",
        ],
        image: appImage
    },
    {
        name: "Software Development",
        items: ["HRM & Payroll",
            "Stock Management",
            "Insurance And Micro Finance",
            "Barcode Generator",
            "Accounting Software",
            "Doctor Prescription",
            "Education Management",
            "POS (Inventory, Restaurant, Ticket etc.)",
            "Laboratory information management system",
            "Enterprise resource planning(ERP)",
            "Business Intelligence Tools (BIT)",
            "Rent management software",
            "Garments Management (Total Solution )",
        ],
        image: softwareImage
    },
    {
        name: "Social Marketing",
        items: ["Facebook",
            "YouTube",
            "Instagram",
            "LinkedIn",
            "Twitter",
            "TikTok",
        ],
        image: marketingImage
    },

];