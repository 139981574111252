import React from 'react';
import { Box, Paper } from '@mui/material';
import DigitalMarketing from "../../images/DigitalMarketing.png";
import Domain_Hosting from "../../images/Domain_Hosting.png";
import Mobile from "../../images/Mobile.png";
import SocialMarketing from "../../images/SocialMarketing.png";
import softwaredevelopment from "../../images/softwaredevelopment.jpg"
import WebDesign from "../../images/WebDesign.png";
import "../../components/styles.css"

const Campaign = () => {
    return (
        <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2, }}>
            <Paper sx={{ p: 0, mr: 3 }}>
                <Box>
                    <img src={Domain_Hosting} alt='' />
                </Box>
            </Paper>
            <Paper sx={{ mr: 3 }}>
                <Box >
                    <img src={WebDesign} alt='' style={{ borderRadius: "5px" }} />
                </Box>
            </Paper>
            <Box mr={3}>
                <img src={Mobile} alt='' />
            </Box>


            <Box mr={3}>
                <img src={softwaredevelopment} alt='' />
            </Box>

            <Box mr={3}>
                <img src={DigitalMarketing} alt='' />
            </Box>

            {/* <Box mr={3}>
                <img src={BulkSMS} alt='' />
            </Box>

            <Box mr={3}>
                <img src={Bulkmail} alt='' />
            </Box> */}

            <Box mr={3}>
                <img src={SocialMarketing} alt='' />
            </Box>
        </Box>
    );
};
export default Campaign;