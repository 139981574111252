import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import PhoneEmail from './PhoneEmail';
import SocialMedia from './SocailMedia';
import registerImage from "../../images/register.jpg"

const ContactUs = () => {
    return (
        <Box sx={{ width: "100%", height: { md: "87vh", xs: "auto" }, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box>
                <Typography variant='h4' sx={{ textAlign: "left", ml: 3, fontWeight: "bold", opacity: 0.7, mt: 2 }}>Contact Us</Typography>
                <Grid container ml={1} >
                    <Grid item md={5} xs={12} sx={{ mt: 2, mr: 2 }}>
                        <PhoneEmail />
                    </Grid>
                    <Grid item md={5} xs={12} sx={{ mt: 2 }}>
                        <SocialMedia />
                    </Grid>
                </Grid>
            </Box>

            <Box>
                <Box sx={{ mt: 1, display: { xs: "none", md: "flex" }, justifyContent: "center" }}>
                    <img alt='' src={registerImage} height={"70%"} width={"70%"} />
                </Box>
                <Box sx={{ mt: 1, display: { xs: "flex", md: "none" }, justifyContent: "center" }}>
                    <img alt='' src={registerImage} height={"70%"} width={"98%"} />
                </Box>
            </Box>
        </Box>
    );
};
export default ContactUs;