import { Box, Button, Divider, Paper, TextField, Typography } from '@mui/material';
import React from 'react';

const ReferalForm = () => {
    return (
        <Paper sx={{ px: { md: 7, xs: 1 }, py: 5 }}>

            <Typography variant='h5' sx={{ fontWeight: "bold", mb: 1 }}>New referal request</Typography>
            <Divider></Divider>
            <Box sx={{ display: { md: "flex", sm: "block" }, m: 2 }}>
                <Typography sx={{ opacity: 0.8, fontWeight: 'bold', mr: 3 }}>Your Friend's Name: </Typography>
                <TextField color='warning' fullWidth size='small' > </TextField>
            </Box>
            <Box sx={{ display: { md: "flex", sm: "block" }, m: 2 }} >
                <Typography sx={{ opacity: 0.8, fontWeight: 'bold', mr: 2, }}>Your Friend's Phone: </Typography>
                <TextField color='warning' fullWidth size='small' > </TextField>
            </Box>
            <Box sx={{ display: { md: "flex", sm: "block" }, m: 2 }}>
                <Typography sx={{ opacity: 0.8, fontWeight: 'bold' }}>Your Friend's Whatsapp: </Typography>
                <TextField color='warning' fullWidth size='small' > </TextField>
            </Box>
            <Button variant='contained' color='warning' sx={{ mt: 2, px: 5, py: 1 }}>Send request</Button>
        </Paper>
    );
};

export default ReferalForm;