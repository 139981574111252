import React from 'react';
import TicketTable from './TicketTable';
import { Grid, Paper } from '@mui/material';
import CreateTicket from './CreateTicket';

const Ticket = () => {
    return (
        <Grid container ml={1} >
            <Grid item component={Paper} md={7} xs={12} sx={{ mt: 2, mr: 2 }}>
                <TicketTable />
            </Grid>
            <Grid component={Paper} item md={4} xs={12} sx={{ mt: 2 }}>
                <CreateTicket />
            </Grid>
        </Grid>
    );
};
export default Ticket;