import React, { useState } from 'react';
import { Box, Button, Divider, IconButton, Input, InputBase, Paper, Typography } from '@mui/material';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import MarkunreadTwoToneIcon from '@mui/icons-material/MarkunreadTwoTone';

const UpdateProfile = () => {
    // const [method, setMethod] = useState("Online Payment ");
    // const [selectMethod, setSelectMethod] = useState("");

    return (
        <Paper elevation={0} sx={{ width: { md: "80%", xs: "100%" } }}>
            <Box sx={{ width: { md: 800, xs: "auto" }, p: 3, display: "flex", flexDirection: "column", justifyContent: "center", }}>
                <Typography variant='h5' fontWeight="bold">Update Profile</Typography>
                <Divider sx={{ mb: 1 }}></Divider>

                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", mb: 2 }}>
                    <Typography fontWeight="bold">Phone Number:</Typography>
                    <Box sx={{ display: "flex", mt: 1 }}>
                        <PermPhoneMsgIcon color='info' sx={{ mr: 2 }} />
                        <Input placeholder="01781-881199" value="01781-881199" inputProps="aria-label" />
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                    <Typography fontWeight="bold">Email:</Typography>
                    <Box sx={{ display: "flex", mt: 1 }}>
                        <MarkunreadTwoToneIcon color='info' sx={{ mr: 2 }} />
                        <Input placeholder="support@facreative.biz" value="support@facreative.biz" inputProps="aria-label" />
                    </Box>
                </Box>
            </Box>
            <Button color='success' variant='outlined' sx={{ px: 4, mb: 2 }}>Update</Button>
        </Paper>
    );
};
export default UpdateProfile;