export const paymentHistory = [
    {
        date: "12.02.2024",
        paymentChannel: "Bkash",
        amount: "1500 tk"
    },
    {
        date: "02.02.2024",
        paymentChannel: "Bkash",
        amount: "500 tk"
    },
    {
        date: "01.01.2024",
        paymentChannel: "Bkash",
        amount: "1500 tk"
    },
    {
        date: "12.12.2023",
        paymentChannel: "Bkash",
        amount: "1500 tk"
    },
]