import React from 'react';

const Packages = () => {
    return (
        <div>
            <h1>This is Packages page</h1>
        </div>
    );
};

export default Packages;