import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { Box, Divider, Typography } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2, textAlign: "center" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8, borderRadius: 3, px: 3, mb: 2 }}
                >
                    <DisabledByDefaultIcon fontSize='40px' color='error' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BankTransferModal = ({ method, selectMethod, bankDetails }) => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box >
            <Button variant={selectMethod ? "contained" : "outlined"} onClick={handleClickOpen} sx={{ marginBottom: 3 }}>
                Pay Now
            </Button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>

                </BootstrapDialogTitle>
                <Divider sx={{ marginTop: 3, fontWeight: "bold" }} >Bank Transfer Processing</Divider>
                <DialogContent sx={{ width: { md: "390px", xs: "300px" }, mt: 1, textAlign: "left" }}>
                    <Typography fontSize={{ md: 18, xs: 14 }} mb={1}>
                        Fund Transfer to
                        <span style={{ color: "#EB6266", fontWeight: "bold" }}> {bankDetails.bankName} </span>
                    </Typography>

                    <Typography fontSize={{ md: 17, xs: 14 }} mb={2}>
                        <span style={{ fontWeight: "bold", backgroundColor: '#EAF0F1', paddingLeft: '5px', paddingRight: "5px", paddingTop: "2px", paddingBottom: '2px', marginRight: '4px' }}>    AC. Name: </span> {bankDetails.accountName}
                    </Typography>
                    <Typography fontSize={{ md: 17, xs: 14 }} mb={2}>
                        <span style={{ fontWeight: "bold", backgroundColor: '#EAF0F1', paddingLeft: '5px', paddingRight: "5px", paddingTop: "2px", paddingBottom: '2px', marginRight: '4px' }}> Account Number: </span> {bankDetails.accountNo}
                    </Typography>
                    <Typography fontSize={{ md: 17, xs: 14 }} mb={2}>
                        <span style={{ fontWeight: "bold", backgroundColor: '#EAF0F1', paddingLeft: '5px', paddingRight: "5px", paddingTop: "2px", paddingBottom: '2px', marginRight: '4px' }}>  Branch:  </span> {bankDetails.branch}
                    </Typography>

                    <Typography fontSize={{ md: 17, xs: 14 }} color="green" fontWeight={"bold"} mb={2}>
                        Please Select Real Time Transfer (NPSB )
                    </Typography>

                </DialogContent>

                <DialogActions sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}>
                    <Button onClick={() => { setOpen(false) }} variant='outlined' color='success'> done</Button>
                </DialogActions>
            </BootstrapDialog>
        </Box>
    );
}
export default BankTransferModal;