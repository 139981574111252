import React, { useState } from 'react'
import { Alert, AlertTitle, Box, Button, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';

const Auth = () => {

    const [openAlert, setOpenAlert] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const { isError, errorMessage } = useState("")
    const [userData, setUserData] = useState({ userName: "", password: "" });
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            document.getElementById('login').click()
        }
    };

    const handleLogin = () => {
        if (!userData.userName) {
            alert("User name is required!")
        }
        else if (!userData.password) {
            alert("Password is Requird.")
        }
        else if (userData.userName === "admin" && userData.password === "admin") {
            // dispatch(Login(userData, navigate));
            // setOpenAlert(true)
            navigate("/dashboard")
        }
        else {
            alert("Sorry. Your username ro password incorrect")
        }
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <Box height={"100vh"} width={"100%"} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

            {
                (openAlert && isError) && <Box>
                    <Alert severity="error" onClose={() => { setOpenAlert(false) }}>
                        <AlertTitle>Login Failed!</AlertTitle>
                        <strong>{errorMessage} </strong>
                    </Alert>
                </Box>
            }
            <Paper sx={{ p: 10 }} elevation={15} >
                <TextField
                    label="User Name"
                    name='userName'
                    variant="standard"
                    focused
                    onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })}
                    sx={{ width: 230, mb: 3 }}
                />
                <br />
                <TextField
                    required
                    label="Password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    variant="standard"
                    onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleTogglePassword} edge="end">
                                    {showPassword ? <VisibilityIcon color='inherit' /> : <VisibilityOffIcon color='inherit' />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <br />
                <Box mt={5} sx={{ display: "flex", justifyContent: "center" }}>
                    <Button id='login'
                        variant='contained'
                        fullWidth
                        onClick={handleLogin}
                    >
                        Log In
                    </Button>
                </Box>
            </Paper>

        </Box>
    )
}
export default Auth;