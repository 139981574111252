import React from 'react';
import ServiceCards from './ServiceCards';
import ServiceRequest from './ServiceRequest';

const Services = () => {
    return (
        <div>
            <ServiceRequest />
            <ServiceCards />
        </div>
    );
};

export default Services;