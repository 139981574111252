import React from 'react';
import { Box, Paper } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const SocialMedia = () => {
    return (
        <Paper sx={{ display: "flex", flexWrap: "wrap", py: 8, px: 4, justifyContent: "space-around", alignItems: "center" }}>
            <Box>
                <a href='https://www.facebook.com/facreative.biz' target='_blank' rel="noreferrer" style={{ textDecoration: "none" }}>
                    <FacebookIcon sx={{ fontSize: 70, borderRadius: 2, border: '1px solid #DAE0E2', color: "#2176FF" }} />
                </a>
            </Box>
            <Box>
                <a href='https://www.youtube.com/@f.a.creativefirmlimited3407' target='_blank' rel="noreferrer">
                    <YouTubeIcon sx={{ fontSize: 70, color: "#FF0000", borderRadius: 2, border: '1px solid #DAE0E2', }} />
                </a>
            </Box>
            <Box>
                <a href='https://www.instagram.com/facreative.biz' target='_blank' rel="noreferrer">
                    <InstagramIcon sx={{ fontSize: 70, border: '1px solid #DAE0E2', borderRadius: 2, color: '#F46B23' }} />
                </a>
            </Box>
            <Box>
                <a href='https://twitter.com/facfltd' target='_blank' rel="noreferrer">
                    <TwitterIcon sx={{ fontSize: 70, border: '1px solid #DAE0E2', borderRadius: 2, color: "#179CF0" }} />
                </a>
            </Box>
            <Box>
                <a href='https://www.linkedin.com/company/facreative' target='_blank' rel="noreferrer">
                    <LinkedInIcon sx={{ fontSize: 70, border: '1px solid #DAE0E2', borderRadius: 2, color: "#0077AF" }} />
                </a>
            </Box>
        </Paper>
    );
};
export default SocialMedia;