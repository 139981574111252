import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import visa from "../images/logo/visa.png";
import bkash from "../images/logo/bkash.png";
import brack from "../images/logo/brack.png";
import mastercard from "../images/logo/mastercard.png";
import nagad from "../images/logo/nagad.png";
import rocket from "../images/logo/rocket.png";
import ucb from "../images/logo/ucb.png";
import upay from "../images/logo/upay.png";
import cityBank from "../images/logo/citybank-8.png";
import paypal from "../images/logo/paypal-8.png";
import sonaliBank from "../images/logo/sonali-8.png";
import amexCard from "../images/logo/Amx-8.png";
import selectImg from "../images/logo/select.png";
import PaymentModal from './PaymentModal';
import "../components/styles.css"
import BankTransferModal from './BankTransferModal';

const PaymentChannel = () => {
    const [method, setMethod] = useState("Online Payment ");
    const [selectMethod, setSelectMethod] = useState("");
    const [bankDetails, setBankDetails] = useState({})
    const [bankTransfer, setBankTransfer] = useState(false)

    return (
        <Paper >
            <Box sx={{ display: "flex", flexWrap: "wrap", }} >

                <Box sx={{ width: ["25%", "25%", "25%"], position: "relative", }}>
                    {
                        selectMethod === "ucb" ? <img alt='' src={selectImg} className='selectedImg' /> : ""
                    }
                    <img alt='' src={ucb} className='paymentLogo'
                        onClick={() => {
                            setMethod("Bank Transper");
                            setSelectMethod("ucb");
                            setBankTransfer(true);
                            setBankDetails({
                                bankName: "UCB",
                                accountName: "F. A.  CREATIVE FIRM LIMITED",
                                accountNo: "0522112000002158",
                                branch: "Anderkilla Branch, Chattogram"
                            })
                        }}

                        style={{ border: `3px solid ${selectMethod === "ucb" ? "green" : "white"}` }}
                    />
                </Box>

                <Box sx={{ width: ["25%", "25%", "25%"], position: "relative", }}>
                    {
                        selectMethod === "sonaliBank" ? <img alt='' src={selectImg} className='selectedImg' /> : ""
                    }
                    <img alt='' src={sonaliBank} className='paymentLogo'
                        onClick={() => {
                            setMethod("Bank Transper");
                            setSelectMethod("sonaliBank");
                            setBankTransfer(true);
                            setBankDetails({
                                bankName: "Sonali Bank Ltd",
                                accountName: "F A CREATIVE FIRM",
                                accountNo: "1008202000297",
                                branch: "Jamal Khan Branch, Chattogram"
                            })
                        }}
                        style={{ border: `3px solid ${selectMethod === "sonaliBank" ? "green" : "white"}` }}
                    />
                </Box>

                <Box sx={{ width: ["25%", "25%", "25%"], position: "relative", }}>
                    {
                        selectMethod === "cityBank" ? <img alt='' src={selectImg} className='selectedImg' /> : ""
                    }
                    <img alt='' src={cityBank} className='paymentLogo'
                        onClick={() => {
                            setMethod("Bank Transper");
                            setSelectMethod("cityBank");
                            setBankTransfer(true);
                            setBankDetails({
                                bankName: "City Bank Ltd",
                                accountName: "F A CREATIVE FIRM",
                                accountNo: "1502436682001",
                                branch: "Anderkilla Branch, Chattogram"
                            })
                        }}
                        style={{ border: `3px solid ${selectMethod === "cityBank" ? "green" : "white"}` }}
                    />
                </Box>

                <Box sx={{ width: ["25%", "25%", "25%"], position: "relative", }}>
                    {
                        selectMethod === "brack" ? <img alt='' src={selectImg} className='selectedImg' /> : ""
                    }
                    <img alt='' src={brack} className='paymentLogo'
                        onClick={() => {
                            setMethod("Bank Transper");
                            setSelectMethod("brack");
                            setBankTransfer(true)
                            setBankDetails({
                                bankName: "BRAC BANK",
                                accountName: "F. A.  CREATIVE FIRM LIMITED",
                                accountNo: "2050400590002",
                                branch: "Agrabad Branch, Chattogram"
                            })
                        }}
                        style={{ border: `3px solid ${selectMethod === "brack" ? "green" : "white"}` }}
                    />
                </Box>

                <Box sx={{ width: ["25%", "25%", "25%"], position: "relative", }}>
                    {
                        selectMethod === "visa" ? <img alt='' src={selectImg} className='selectedImg' /> : ""
                    }
                    <img alt='' src={visa} className='paymentLogo' onClick={() => { setMethod("VISA Payment"); setSelectMethod("visa"); setBankTransfer(false) }}
                        style={{ border: `3px solid ${selectMethod === "visa" ? "green" : "white"}` }}
                    />
                </Box>

                <Box sx={{ width: ["25%", "25%", "25%"], position: "relative", }}>
                    {
                        selectMethod === "amexCard" ? <img alt='' src={selectImg} className='selectedImg' /> : ""
                    }
                    <img alt='' src={amexCard} className='paymentLogo' onClick={() => { setMethod("American Express"); setSelectMethod("amexCard"); setBankTransfer(false) }}
                        style={{ border: `3px solid ${selectMethod === "amexCard" ? "green" : "white"}` }}
                    />
                </Box>

                <Box sx={{ width: ["25%", "25%", "25%"], position: "relative", }}>
                    {
                        selectMethod === "paypal" ? <img alt='' src={selectImg} className='selectedImg' /> : ""
                    }
                    <img alt='' src={paypal} className='paymentLogo' onClick={() => { setMethod("paypal Payment"); setSelectMethod("paypal"); setBankTransfer(false) }}
                        style={{ border: `3px solid ${selectMethod === "paypal" ? "green" : "white"}` }}
                    />
                </Box>

                <Box sx={{ width: ["25%", "25%", "25%"], position: "relative", }}>
                    {
                        selectMethod === "mastercard" ? <img alt='' src={selectImg} className='selectedImg' /> : ""
                    }
                    <img alt='' src={mastercard} className='paymentLogo' onClick={() => { setMethod("Card payment"); setSelectMethod("mastercard"); setBankTransfer(false) }}
                        style={{ border: `3px solid ${selectMethod === "mastercard" ? "green" : "white"}` }}
                    />
                </Box>

                <Box sx={{ width: ["25%", "25%", "25%"], position: "relative", }}>
                    {
                        selectMethod === "bkash" ? <img alt='' src={selectImg} className='selectedImg' /> : ""
                    }
                    <img alt='' src={bkash} className='paymentLogo' onClick={() => { setMethod("Bkash Payment"); setSelectMethod("bkash"); setBankTransfer(false) }}
                        style={{ border: `3px solid ${selectMethod === "bkash" ? "green" : "white"}` }}
                    />
                </Box>

                <Box sx={{ width: ["25%", "25%", "25%"], position: "relative", }}>
                    {
                        selectMethod === "nagad" ? <img alt='' src={selectImg} className='selectedImg' /> : ""
                    }
                    <img alt='' src={nagad} className='paymentLogo' onClick={() => { setMethod("Nagad Payment"); setSelectMethod("nagad"); setBankTransfer(false) }}
                        style={{ border: `3px solid ${selectMethod === "nagad" ? "green" : "white"}` }} />
                </Box>

                <Box sx={{ width: ["25%", "25%", "25%"], position: 'relative', }}>
                    {
                        selectMethod === "rocket" ? <img alt='' src={selectImg} className='selectedImg' /> : ""
                    }
                    <img alt='' src={rocket} className='paymentLogo' onClick={() => { setMethod("Rocket Payment"); setSelectMethod("rocket"); setBankTransfer(false) }}
                        style={{ border: `3px solid ${selectMethod === "rocket" ? "green" : "white"}` }}
                    />
                </Box>

                <Box sx={{ width: ["25%", "25%", "25%"], position: "relative", }}>
                    {
                        selectMethod === "upay" ? <img alt='' src={selectImg} className='selectedImg' /> : ""
                    }
                    <img alt='' src={upay} className='paymentLogo' onClick={() => { setMethod("Upay Payment"); setSelectMethod("upay"); setBankTransfer(false) }}
                        style={{ border: `3px solid ${selectMethod === "upay" ? "green" : "white"}` }}
                    />
                </Box>

            </Box>
            {
                bankTransfer ?
                    <BankTransferModal bankDetails={bankDetails} selectMethod={selectMethod} />
                    :
                    <PaymentModal method={method} selectMethod={selectMethod} />
            }

        </Paper>
    );
};
export default PaymentChannel;