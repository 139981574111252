import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardHeader, CardMedia, CardContent, CardActions, Collapse, Avatar, Typography, IconButton, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const CardItem = ({ data }) => {
    const [expanded, setExpanded] = React.useState(false);


    const firstArray = data.items.slice(0, 3);
    const secondArray = data.items.slice(3);


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ maxWidth: 345, margin: 3, height: "max-content" }}>
            <CardHeader
                title={<span style={{ fontWeight: "bold" }}>{data.name}</span>}
                sx={{ fontSize: 30 }}
            />
            <CardMedia
                component="img"
                height="194"
                image={data.image}
                alt="Paella dish"
            />
            <CardContent>
                {
                    firstArray.map((item, index) => (
                        <Box key={index} sx={{ border: "1px solid gray", p: 1, pl: 1, display: "flex" }}>
                            <Typography textAlign="left"> <span style={{ fontSize: "20px" }}> ◆ </span> {item} </Typography>
                        </Box>
                    ))
                }
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {
                        secondArray.map((item, index) => (
                            <Box key={index} sx={{ border: "1px solid gray", p: 1, pl: 1, display: "flex" }}>
                                <Typography textAlign="left" > <span style={{ fontSize: "20px" }}> ◆ </span> {item} </Typography>
                            </Box>
                        ))
                    }
                </Collapse>

            </CardContent>

            <CardActions disableSpacing>
                <Typography onClick={handleExpandClick} sx={{ cursor: "pointer" }}>Show {expanded ? "less" : "more"} </Typography>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
        </Card>
    );
}
export default CardItem;