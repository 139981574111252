import React from 'react';

const MyServices = () => {
    return (
        <div>
            <h1>My Services</h1>
        </div>
    );
};

export default MyServices; <h1>My Services</h1>