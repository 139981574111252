import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import ReferalForm from './ReferalForm';
import ReferalDashboard from './ReferalDashboard';

const Referal = () => {
    return (
        <Box sx={{ width: "100%" }}>
            <Typography variant='h4' sx={{ textAlign: "left", ml: 3, fontWeight: "bold", opacity: 0.7, mt: 2 }}>Refer a Friend</Typography>
            <Grid container ml={1} >
                <Grid item md={6} xs={12} sx={{ mt: 2, mr: 2 }}>
                    <ReferalForm />
                </Grid>
                <Grid item md={4} xs={12} sx={{ mt: 2 }}>
                    <ReferalDashboard />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Referal;