import { Grid, Paper, Box } from '@mui/material';
import React from 'react';
import Profile from '../../components/Profile';
import UpdateProfile from './UpdateProfile';
import TicketHistory from '../../components/TicketHistory';
import PaymentHistory from '../../components/PaymentHistory';
import MyServices from './MyServices';

const MyAccount = () => {
    return (
        <div>
            <Grid container ml={1} >
                <Grid item component={Paper} md={7} xs={12} sx={{ mt: 2, mr: 2 }}>
                    <UpdateProfile />
                </Grid>
                <Grid component={Paper} item md={4} xs={12} sx={{ mt: 2 }}>
                    <Profile />
                </Grid>
            </Grid>

            {/* Payment and Ticket History */}
            <Grid container ml={1}>
                <Grid item md={5} xs={12} sx={{ mt: 2, mr: 2 }}>
                    <TicketHistory />
                </Grid>
                <Grid item md={6.6} xs={12} sx={{ mt: 2 }}>
                    <PaymentHistory />
                </Grid>
            </Grid>
            <MyServices />
        </div>
    );
};
export default MyAccount;