import { FormControl, InputLabel, Paper, Select, Typography, MenuItem, TextareaAutosize, Box, Button, TextField } from '@mui/material';
import React from 'react';

const CreateTicket = () => {
    const [category, setCategory] = React.useState('');

    const handleChange = (event) => {
        setCategory(event.target.value);
    };

    return (
        <Box >

            <Paper sx={{ py: 7 }}>
                <Typography fontWeight="bold" variant='h6'>Open a Ticket / Support Request</Typography>
                <Box sx={{ mb: 2, px: 8 }}>
                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="demo-simple-select-standard-label"> Select Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={category}
                            onChange={handleChange}
                            label="Category"
                        >
                            <MenuItem value={"Website Problem"}> Website Problem </MenuItem>
                            <MenuItem value={"Mobile Apps Problem"}> Mobile Apps Problem </MenuItem>
                            <MenuItem value={"Software Problem"}> Software Problem </MenuItem>
                            <MenuItem value={"Domain & Hosting Renew"}> Domain & Hosting Renew </MenuItem>
                            <MenuItem value={"Social Merketing"}> Social Merketing </MenuItem>
                            <MenuItem value={"Others"}> Others </MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ mb: 3, px: 8 }}>
                    <TextField
                        fullWidth
                        id="outlined-textarea"
                        label="Details:"
                        placeholder="write your message"
                        multiline
                        minRows={4}
                    />
                </Box>

                <Button variant='contained' color='secondary'>Submit</Button>
            </Paper>
        </Box>
    );
};
export default CreateTicket;