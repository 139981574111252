import React from 'react';
import StatusBox from './StatusBox';
import Campaign from './Campaign';
import { Box, Grid, Paper } from '@mui/material';
import Profile from '../../components/Profile';
import PaymentChannel from '../../components/PaymentChannel';
import TicketHistory from '../../components/TicketHistory';
import PaymentHistory from '../../components/PaymentHistory';
import Marquee from 'react-fast-marquee';

const Dashboard = () => {
    return (
        <div>
            <Box m={1}>
                <StatusBox />
            </Box>

            <Grid container justifyContent="center" >
                <Grid item xs={12} md={12}>
                    <Paper sx={{ height: '100%', maxWidth: { md: "100%", xs: "420px" } }}>
                        <Marquee>
                            <Campaign />
                        </Marquee>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container ml={1} >
                <Grid item component={Paper} md={5} xs={12} sx={{ mt: 2, mr: 2 }}>
                    <Profile />
                </Grid>
                <Grid item md={6.6} xs={12} sx={{ mt: 2 }}>
                    <PaymentChannel />
                </Grid>
            </Grid>
            {/* Payment and Ticket History */}
            <Grid container ml={1}>
                <Grid item md={5} xs={12} sx={{ mt: 2, mr: 2 }}>
                    <TicketHistory />
                </Grid>
                <Grid item md={6.6} xs={12} sx={{ mt: 2 }}>
                    <PaymentHistory />
                </Grid>
            </Grid>
        </div>
    );
};
export default Dashboard;